var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { css } from '@emotion/react';
import React from 'react';
import { scrollMessageSenderStyle, createScrollMessageSender, } from '../utils/scroll-message-sender';
import FooterConversionButton from './FooterConversionButton';
import Img from './Img';
import NavigationMenu from './NavigationMenu';
var Header = function (_a) {
    var _b, _c;
    var setting = _a.setting, icons = _a.icons;
    var styles = {
        header: css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-between;\n      padding: 0.5em;\n      position: sticky;\n      top: 0;\n      left: 0;\n      z-index: 1;\n      background-color: white;\n      @media (max-width: 769px) {\n        position: static;\n        padding: ", ";\n      }\n    "], ["\n      display: flex;\n      justify-content: space-between;\n      padding: 0.5em;\n      position: sticky;\n      top: 0;\n      left: 0;\n      z-index: 1;\n      background-color: white;\n      @media (max-width: 769px) {\n        position: static;\n        padding: ", ";\n      }\n    "])), !setting.iconImage ||
            !setting.title ||
            !setting.footerCvButtons
            ? 0
            : '0.5em'),
        headerInner: css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      display: flex;\n      align-items: center;\n      word-break: break-all;\n    "], ["\n      display: flex;\n      align-items: center;\n      word-break: break-all;\n    "]))),
        menuWrapper: css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n      display: flex;\n    "], ["\n      display: flex;\n    "]))),
        logoArea: css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      display: flex;\n      justify-content: space-between;\n      align-items: flex-start;\n      width: ", ";\n    "], ["\n      display: flex;\n      justify-content: space-between;\n      align-items: flex-start;\n      width: ", ";\n    "])), ((_b = setting.navigationMenu) === null || _b === void 0 ? void 0 : _b.items.length)
            ? 'calc(100% - 50px)'
            : '100%'),
        buttonArea: css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n      display: flex;\n      min-width: max-content;\n      @media (max-width: 769px) {\n        z-index: 1;\n      }\n    "], ["\n      display: flex;\n      min-width: max-content;\n      @media (max-width: 769px) {\n        z-index: 1;\n      }\n    "]))),
        logo: css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      width: auto;\n      height: 50px;\n      margin-right: 0.5em;\n    "], ["\n      width: auto;\n      height: 50px;\n      margin-right: 0.5em;\n    "]))),
        logoWrapper: css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n      min-width: max-content;\n    "], ["\n      min-width: max-content;\n    "]))),
        title: css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n      font-size: 1.5em;\n      font-weight: bold;\n      color: #555555;\n    "], ["\n      font-size: 1.5em;\n      font-weight: bold;\n      color: #555555;\n    "]))),
    };
    return setting.iconImage ||
        setting.title ||
        setting.footerCvButtons ||
        setting.navigationMenu ? (_jsx("header", __assign({ css: styles.header }, { children: _jsxs("div", __assign({ css: styles.logoArea }, { children: [_jsxs("div", __assign({ css: [styles.headerInner, scrollMessageSenderStyle()], onClick: createScrollMessageSender('site-edit') }, { children: [setting.iconImage && (_jsx("p", __assign({ css: styles.logoWrapper }, { children: _jsx(Img, { css: styles.logo, src: setting.iconImage, alt: '' }) }))), setting.title && _jsx("h1", __assign({ css: styles.title }, { children: setting.title }))] })), _jsx("div", __assign({ css: styles.menuWrapper }, { children: ((_c = setting.navigationMenu) === null || _c === void 0 ? void 0 : _c.items[0]) && (_jsx(NavigationMenu, { navigationMenu: setting.navigationMenu, setting: setting })) })), _jsx("div", __assign({ css: styles.buttonArea }, { children: _jsx(FooterConversionButton, { footerCvButtons: setting.footerCvButtons, setting: setting, icons: icons }) }))] })) }))) : null;
};
export default Header;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
