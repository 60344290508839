var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { css } from '@emotion/react';
import { isInRealTimePreviewMode } from './preview';
// クリック時にスクロールイベントを親フレームにpostMessageで発火するハンドラを生成
export var createScrollMessageSender = function (id) {
    return function (event) {
        if (isInRealTimePreviewMode()) {
            event.preventDefault();
            window.parent.postMessage({
                type: 'scroll',
                id: id,
            }, '*');
        }
    };
};
// プレビュークリックできるUIのCSS
var SCROLL_MESSAGE_SENDER_STYLE = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  :hover {\n    cursor: pointer;\n    position: relative;\n    left: 0;\n    :after {\n      content: '';\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      border: 2px dotted red;\n      pointer-events: none;\n    }\n    :before {\n      z-index: 1000;\n      font-size: 12px;\n      padding: 4px;\n      left: 0;\n      position: absolute;\n      color: red;\n      background-color: #333;\n      content: '\u3053\u306E\u30B3\u30F3\u30C6\u30F3\u30C4\u3092\u7DE8\u96C6';\n      opacity: 0.8;\n      font-family: sans-serif;\n    }\n  }\n"], ["\n  :hover {\n    cursor: pointer;\n    position: relative;\n    left: 0;\n    :after {\n      content: '';\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n      border: 2px dotted red;\n      pointer-events: none;\n    }\n    :before {\n      z-index: 1000;\n      font-size: 12px;\n      padding: 4px;\n      left: 0;\n      position: absolute;\n      color: red;\n      background-color: #333;\n      content: '\u3053\u306E\u30B3\u30F3\u30C6\u30F3\u30C4\u3092\u7DE8\u96C6';\n      opacity: 0.8;\n      font-family: sans-serif;\n    }\n  }\n"])));
// プレビュークリックできるUIのCSSを生成(リアルタイムプレビュー内の場合のみ)
export var scrollMessageSenderStyle = function () {
    return isInRealTimePreviewMode() ? SCROLL_MESSAGE_SENDER_STYLE : null;
};
var templateObject_1;
